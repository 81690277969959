<template>
  <div class="login-page d-flex align-items-center">
    <img class="logo"
         src="../assets/images/login-logo.png"
         alt="Premat">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title text-center">Bienvenue<br> sur l'outil de préparation<br> d'itinéraires Premat</h3>
        <div v-if="$store.state.user.error"
             class="alert alert-danger mb-5"
             role="alert">
          {{ $store.state.user.error }}
        </div>
        <FormInput v-model="form.username"
                   @force-submit="submit"
                   id="username"
                   type="text"
                   placeholder="Identifiant exploitant"
                   name="username"
                   class="mb-5"/>
        <FormInput v-model="form.password"
                   @force-submit="submit"
                   id="password"
                   type="password"
                   placeholder="Mot de passe"
                   name="password"
                   class="mb-5"/>
        <br>
        <br>
        <FormSubmit @submit="submit"
                    :disabled="!formFilled"
                    :block="true"
                    classes="btn,btn-block,btn-secondary"
                    text="Se Connecter"/>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/FormInput'
import FormSubmit from '@/components/FormSubmit'
import { mapGetters, mapActions } from 'vuex'
import { listCountryDepartments } from '@/api/premat'

export default {
  name: 'Login',
  components: {
    FormInput,
    FormSubmit
  },
  data () {
    return {
      form: {
        username: null,
        password: null
      }
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    }),
    formFilled () {
      return !!this.form.username && !!this.form.password
    }
  },
  methods: {
    ...mapActions({
      login: 'user/login'
    }),
    submit () {
      if (!this.formFilled) return
      this.login(this.form)
        .then(() => {
          listCountryDepartments().then(response => {
            // we store all departments in local storage on login
            localStorage.setItem('departments', JSON.stringify(response.data.departments))
            // we redirect the user to the authorizations list
            this.$router.push({ name: 'authorizations.index' })
          })
        })
        .catch(() => {
          this.form.password = null
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-page {
    min-height: 100vh;
    background: url('../assets/images/login-background.jpg') no-repeat center center fixed;
    background-size: cover;
    position: relative;
  }

  .logo {
    position: absolute;
    top: 60px;
    right: 90px;
    width: 306px;
  }

  .card {
    margin-left: 200px;
  }

  .card-body {
    padding: 60px 45px 70px 45px;
  }

  .card-title {
    padding: 0 20px;
  }
</style>
