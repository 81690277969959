<template>
  <div class="form-group">
    <button @click="submit"
            :disabled="disabled"
            type="button"
            :class="buttonClasses">
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    classes: {
      type: String,
      required: false,
      default: null
    },
    block: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    buttonClasses () {
      const classes = []
      if (this.classes) {
        this.classes.split(',').forEach(item => {
          classes.push(item)
        })
      } else {
        classes.push('btn')
        classes.push('btn-primary')
      }
      if (this.block) {
        classes.push('btn-block')
      }
      return classes
    }
  },
  methods: {
    submit () {
      this.$emit('submit')
    }
  }
}
</script>
